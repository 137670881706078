import { Component } from 'preact';
// import { route } from 'preact-router';

export default class Redirect extends Component {
	componentWillMount() {
		// route(this.props.to, false);
		if (typeof window !== 'undefined') {
			window.location.replace('/404-not-found');
		}
	}

	render() {
		return null;
	}
}